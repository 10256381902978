import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { DndDropEvent } from 'ngx-drag-drop';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MonthlyCalendarItem } from '../../schedule-api.service';
import { AuthService } from '../../../auth/auth.service';
import ScheduleCalendarService from '../schedule-calendar.service';
import { MoveLessonDndModalComponent } from '../modals/move-lesson-dnd-modal/move-lesson-dnd-modal.component';
import {
  dateToYyyyMmDd,
  daysBetweenDates,
  minutesToHours,
  timeStringToTimeSlotDiapason
} from '@shared/utils';
import DealService from '../../../deal/deal.service';
import { NotificationService } from '@shared/services/notification.service';
import { RemoveReservationModalComponent } from '../modals/remove-reservation-modal/remove-reservation-modal.component';
import FreeSlotsService from '../../../free-slots/free-slots.service';
import { RemoveFreeSlotModalComponent } from '../modals/remove-free-slot-modal/remove-free-slot-modal.component';
import LanguageService from '@shared/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MoveReservationModalComponent } from '../modals/move-reservation-modal/move-reservation-modal.component';

@Component({
  selector: 'mathema-schedule-calendar-slot',
  templateUrl: './schedule-calendar-slot.component.html',
  styleUrls: ['./schedule-calendar-slot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarSlotComponent implements OnInit, OnDestroy {

  @Input() item: MonthlyCalendarItem;
  @Input() viewType: 'month' | 'week';
  @Input() dealMode: boolean;
  @Input() freeSlotsMode: boolean;
  @Input() teacherSearchMode: boolean;
  @Input() isVacation: boolean;

  @Output() onSlotSelected: EventEmitter<void> = new EventEmitter<void>();

  public isActive: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly authService: AuthService,
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly modalService: NzModalService,
    public readonly dealService: DealService,
    private readonly freeSlotsService: FreeSlotsService,
    private readonly notificationService: NotificationService,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
  ) {

  }

  public ngOnInit(): void {
    let service;

    if (this.dealMode) {
      service = this.dealService;
    } else if (this.freeSlotsMode) {
      service = this.freeSlotsService;
    } else {
      service = this.scheduleCalendarService;
    }

    this.subscriptions.push(service.activeSlot.subscribe(activeSlot => {
      this.isActive = activeSlot?.id?.startsWith(this.generateUniqueSlotId());
    }));
  }

  public get tooltipTitle(): string {
    if (this.item.isReservationWithoutSlot && !this.item.isLesson) {
      return this.translateService.instant('calendar.slot.tooltips.no_free_slots_or_vacation');
    } else if (this.freeSlotsMode && this.item.isLesson) {
      return this.translateService.instant('calendar.slot.tooltips.slot_taken');
    }

    return '';
  }

  public get tooltipTrigger(): 'hover' | null {
    if (this.item.isReservationWithoutSlot && !this.item.isLesson) {
      return 'hover';
    } else if (this.freeSlotsMode && this.item.isLesson) {
      return 'hover';
    }

    return null;
  }

  public onLessonDroppedInSlot(event: DndDropEvent, freeSlotItem: MonthlyCalendarItem) {
    const lessonItem: MonthlyCalendarItem = event.data;

    const lessonTimeDiapason = timeStringToTimeSlotDiapason(lessonItem.time);
    const freeSlotTimeDiapason = timeStringToTimeSlotDiapason(freeSlotItem.time);

    if (lessonTimeDiapason.length > freeSlotTimeDiapason.length) {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.slot.errors.duration_error'));
      return;
    }

    this.modalService.create({
      nzClosable: false,
      nzTitle: null,
      nzFooter: null,
      nzCentered: true,
      nzWrapClassName: 'move-lesson-modal',
      nzContent: MoveLessonDndModalComponent,
      nzData: {
        lessonItem,
        freeSlotItem,
        showNotification: this.isSendNotificationNeeded(),
      },
    });
  }

  public isSendNotificationNeeded(): boolean {
    return this.authService.isAdmin() || this.authService.isManager();
  }

  public onClickFreeSlot(): void {
    if (this.teacherSearchMode && this.isVacation) {
      return;
    }

    if (this.teacherSearchMode && !this.isSlotDisabled) {
      this.isActive = true;
      const id = this.generateUniqueSlotId();
      const [type, slotItemId, slotItemTime, slotItemDate] = id.split('|');
      this.scheduleCalendarService.activeSlot.next({
        id,
        type: type as 'freeSlot',
        slotItemId,
        slotItemTime,
        slotItemDate,
      });
      this.scheduleCalendarService.calendarDate.next(new Date(slotItemDate));
      return;
    }

    if (!this.dealMode && !this.freeSlotsMode) {
      this.isActive = false;
      this.scheduleCalendarService.activeSlot.next(null);
    } else if (this.dealMode) {
      this.onClickFreeSlotForDeal();
    } else if (this.freeSlotsMode) {
      this.onClickFreeSlotForFreeSlots();
    }
  }

  private onClickFreeSlotForFreeSlots(): void {
    if (this.item.isMarkedForFreeSlot) {
      return;
    }

    this.isActive = !this.isActive;

    if (this.isActive) {
      const id = this.generateUniqueSlotId();
      const [type, slotItemId, slotItemTime, slotItemDate] = id.split('|');
      this.freeSlotsService.activeSlot.next({
        id,
        type: type as 'freeSlot',
        slotItemId,
        slotItemTime,
        slotItemDate,
      });
    } else {
      this.freeSlotsService.activeSlot.next(null);
    }
  }

  public onClickRemoveFreeSlot(): void {
    if (this.isSlotDisabled) {
      return;
    }

    this.modalService.create({
      nzMaskClosable: false,
      nzClosable: false,
      nzTitle: null,
      nzFooter: null,
      nzCentered: true,
      nzWrapClassName: 'remove-free-slot-modal',
      nzContent: RemoveFreeSlotModalComponent,
      nzData: {
        freeSlotItem: this.item,
      },
    });
    return;
  }

  public onClickFreeSlotForDeal(clickType?: 'edit' | 'remove'): void {
    if (this.item.isMarkedForReservation || this.item.isDisabled || this.isSlotDisabled || this.isVacation) {
      return;
    }

    if (this.dealService.activeSlot.value) {
      return;
    }

    if (this.item.isConfirmedReservation && !this.item.isConducted && !clickType) {
      return;
    }

    if (clickType === 'remove') {
      this.modalService.create({
        nzClosable: false,
        nzTitle: null,
        nzFooter: null,
        nzCentered: true,
        nzWrapClassName: 'remove-reservation-modal',
        nzContent: RemoveReservationModalComponent,
        nzData: {
          reservationItem: this.item,
        },
      });
      return;
    }

    if (clickType === 'edit') {
      this.modalService.create({
        nzClosable: false,
        nzTitle: null,
        nzFooter: null,
        nzCentered: true,
        nzWrapClassName: 'move-reservation-modal',
        nzContent: MoveReservationModalComponent,
        nzData: {
          reservationItem: this.item,
        },
      });
      return;
    }

    if (clickType === 'edit') {
      return;
    }

    const lessonDuration = this.dealService.activeLessonDuration.value;
    const { setHours, totalHours } = this.dealService.scheduleMetrics.value;
    if (setHours >= totalHours) {
      this.notificationService.showErrorMessage(this.translateService.instant('calendar.slot.errors.max_hours_set_error'));
      return;
    } else if (totalHours - setHours < minutesToHours(lessonDuration)) {
      this.notificationService.showErrorMessage(this.translateService.instant('calendar.slot.errors.not_enough_hours', { lessonDuration }));
      return;
    }

    this.isActive = !this.isActive;

    if (this.isActive) {
      const id = this.generateUniqueSlotId();
      const [type, slotItemId, slotItemTime, slotItemDate] = id.split('|');
      this.dealService.activeSlot.next({
        id,
        type: type as 'freeSlot',
        slotItemId,
        slotItemTime,
        slotItemDate,
      });
    } else {
      this.dealService.activeSlot.next(null);
    }
  }

  public changeActiveSlotIndex(freeSlotAction?: 'firstLesson' | 'additionalLesson'): void {
    if (this.dealMode || this.freeSlotsMode || this.teacherSearchMode) {
      return;
    }

    this.isActive = !this.isActive;

    if (this.isActive) {
      const id = this.generateUniqueSlotId();
      const [type, slotItemId, slotItemTime, slotItemDate] = id.split('|');
      this.scheduleCalendarService.activeSlot.next({
        id,
        type: type as 'lesson' | 'freeSlot',
        slotItemId,
        slotItemTime,
        slotItemDate,
        freeSlotAction,
      });
      this.onSlotSelected.emit();
    } else {
      this.scheduleCalendarService.activeSlot.next(null);
    }
  }

  public generateUniqueSlotId(): string {
    const type = this.item.isLesson ? 'lesson' : 'freeSlot';
    return `${type}|${this.item.id}|${this.item.time}|${this.item.date}`;
  }

  public getTeacherBlockedTooltipForStuff(notTakeNewStudentsAt: Date, isTeacherBlocked: boolean, blockedAt: Date): string {
    if (notTakeNewStudentsAt && !isTeacherBlocked) {
      return this.translateService.instant('calendar.slot.tooltips.teacher_not_take_students');
    }

    const unblockFrom = new Date(blockedAt);
    unblockFrom.setDate(unblockFrom.getDate() + 14);

    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return this.translateService.instant('calendar.slot.tooltips.teacher_blocked_until', { unblockDate: formatter.format(unblockFrom) });
  }

  public get isSlotDisabled(): boolean {
    const now = new Date();
    const nowYyMmDd = dateToYyyyMmDd(now);
    const { date, time } = this.item;

    if (nowYyMmDd === date) {
      if (this.authService.isClient()) {
        return true;
      }

      const [, itemEndTime] = time.split(' - ');
      const itemEndTimeJsDate = new Date(`${date}T${itemEndTime}`);
      return +itemEndTimeJsDate < +now;
    }

    return daysBetweenDates(nowYyMmDd, date) > 0;
  }

  public isTimeHourOrMore(time: string): boolean {
    return timeStringToTimeSlotDiapason(time).length >= 12;
  }

  public ngOnDestroy(): void {
  for(const subscription of this.subscriptions) {
  subscription.unsubscribe();
}
  }
}
