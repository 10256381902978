<ng-container *ngIf="viewType === 'month'">
  <ng-template [ngTemplateOutlet]="item.isLesson ? monthLessonSlot : monthFreeTimeSlot"></ng-template>
</ng-container>

<ng-container *ngIf="viewType === 'week'">
  <ng-template [ngTemplateOutlet]="item.isLesson ? weekLessonSlot : weekFreeTimeSlot"></ng-template>
</ng-container>

<ng-template #monthLessonSlot>
  <div class="item lesson" (click)="$event.stopPropagation(); changeActiveSlotIndex()" [class.checked]="isActive"
    [class.first]="item.type === 'first'" [class.free-slot-mode]="freeSlotsMode" nz-tooltip
    [nzTooltipTrigger]="tooltipTrigger" [nzTooltipTitle]="tooltipTitle">
    <ng-template [ngTemplateOutlet]="monthItemContent"></ng-template>
  </div>
</ng-template>

<ng-template #monthFreeTimeSlot>
  <div
    [dndDisableIf]="dealMode || freeSlotsMode || isSlotDisabled || isVacation"
    dndDropzone
    (dndDrop)="onLessonDroppedInSlot($event, item)"
    nz-dropdown
    nzTrigger="click"
    [nzDisabled]="item.isLesson || authService.isTeacherOrClient() || dealMode || freeSlotsMode || isSlotDisabled || isVacation"
    [nzDropdownMenu]="freeSlotSubmenu"
    [nzOverlayClassName]="'free-slot-submenu'"
    class="item slot"
    (click)="$event.stopPropagation(); onClickFreeSlot()"
    [class.checked]="isActive"
    [class.deal-slot]="dealMode"
    [class.free-slot-mode]="freeSlotsMode" [class.marked-for-reservation]="item.isMarkedForReservation"
    [class.marked-for-free-slot]="item.isMarkedForFreeSlot"
    [class.is-repeat]="item.isRepeat"
    [class.confirmed-reservation]="item.isConfirmedReservation"
    [class.marked-for-future-subscription]="item.markedForFutureSubscription"
    [class.reservation-without-slot]="item.isReservationWithoutSlot"
    [class.disabled]="item.isDisabled"
    nz-tooltip
    [nzTooltipTrigger]="tooltipTrigger"
    [nzTooltipTitle]="tooltipTitle">
    <ng-template [ngTemplateOutlet]="monthItemContent"></ng-template>
  </div>
</ng-template>

<ng-template #monthItemContent>
  <div class="item-content">
    <div class="info">
      <div class="time">{{item.time}}</div>
      <ng-container *ngIf="item.isLesson && freeSlotsMode; else defaultMonthItemContent"></ng-container>
      <ng-template #defaultMonthItemContent>
        <div *ngIf="item.isLesson; else freeSlotLabel">
          {{item.student?.firstName}} {{item.student?.lastName}}
        </div>
        <ng-template #freeSlotLabel>
          <ng-container *ngIf="!freeSlotsMode">
            <div class="free-slot-text"
              *ngIf="!(item.isMarkedForReservation || item.isConfirmedReservation); else markedForReservationLabel">
              {{'calendar.slot.empty_slot' | translate}}</div>
          </ng-container>
          <ng-container *ngIf="freeSlotsMode">
            <div class="free-slot-text">{{'calendar.slot.available' | translate}}{{item.isRepeat ? ('calendar.slot.repeat' | translate ): ''}}</div>
          </ng-container>
          <ng-template #markedForReservationLabel>
            <div class="free-slot-text">
              {{'calendar.slot.' + (item.isLessonPaid ? 'paid_lesson' : 'reservation') | translate}}
              {{item.markedForFutureSubscription ? ('calendar.slot.next_sub' | translate) : ''}}
              {{item.isRepeat ? ('calendar.slot.repeat' | translate) : ''}}
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
    <div class="icons">
      <ng-container *ngIf="!freeSlotsMode">
        <ng-container *ngIf="item.type === 'first'">
          <mathema-slot-icon [type]="'first'" [color]="isActive && '#fff'"></mathema-slot-icon>
          <mathema-slot-icon *ngIf="item.status === 'reserved'" [type]="'reserved'"
            [color]="isActive && '#fff'"></mathema-slot-icon>
          <mathema-slot-icon *ngIf="item.isConducted" [type]="'conducted'" [color]="isActive && '#fff'"
            [color2]="isActive && '#1CC28FFF'"></mathema-slot-icon>
        </ng-container>

        <ng-container *ngIf="item.type === 'regular'">
          <mathema-slot-icon *ngIf="item.isConducted" [type]="'conducted'" [color]="isActive && '#fff'"
            [color2]="isActive && '#1CC28FFF'"></mathema-slot-icon>
          <mathema-slot-icon *ngIf="!item.isConducted" [type]="'regular'"
            [color]="isActive && '#fff'"></mathema-slot-icon>

          <ng-container *ngIf="item.status === 'reserved'">
            <mathema-slot-icon *ngIf="item.dealId" [type]="'reserved'"
              [color]="isActive ? '#fff' : (item.markedForFutureSubscription && !authService.isTeacherOrClient() && '#ff6868')"></mathema-slot-icon>
            <mathema-slot-icon *ngIf="!item.dealId" [type]="'reserved'"
              [color]="isActive ? '#004D40' : '#949494'"></mathema-slot-icon>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="item.status === 'booked'">
          <mathema-slot-icon *ngIf="authService.isTeacherOrClient() && !item.isConducted" [type]="'paid'"
            [color]="isActive && '#fff'"></mathema-slot-icon>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!item.isLesson && !authService.isTeacherOrClient() && !dealMode && !freeSlotsMode">
        <mathema-slot-icon *ngIf="!isActive && !isSlotDisabled && !isVacation" [type]="'add-slot'"></mathema-slot-icon>
        <mathema-slot-icon *ngIf="isActive" [type]="'check'"></mathema-slot-icon>
      </ng-container>
      <ng-container *ngIf="!item.isLesson && !authService.isTeacherOrClient() && dealMode">
        <mathema-slot-icon
          *ngIf="!isActive && !item.isMarkedForReservation && !item.isConfirmedReservation && !item.isDisabled && !isSlotDisabled && !isVacation"
          [type]="'add-slot'"></mathema-slot-icon>
        <mathema-slot-icon *ngIf="isActive" [type]="'check'" color="#613CB0"></mathema-slot-icon>
        <mathema-slot-icon *ngIf="item.isMarkedForReservation" [type]="'reserved'"
          [color]="item.isReservationWithoutSlot ? '#b03c3c' : '#613CB0'"></mathema-slot-icon>
        <mathema-slot-icon
          (click)="onClickFreeSlotForDeal('edit')"
          *ngIf="item.isConfirmedReservation && !item.isConducted && !isSlotDisabled && !dealService.activeSlot.value"
          [type]="'edit'" [color]="item.isReservationWithoutSlot ? '#b03c3c' : '#613CB0'"></mathema-slot-icon>
        <mathema-slot-icon
          (click)="onClickFreeSlotForDeal('remove')"
          *ngIf="item.isConfirmedReservation && !item.isConducted && !isSlotDisabled && !dealService.activeSlot.value"
          [type]="'bin'" [color]="item.isReservationWithoutSlot ? '#b03c3c' : '#613CB0'"></mathema-slot-icon>
      </ng-container>

      <ng-container *ngIf="!item.isLesson && freeSlotsMode">
        <mathema-slot-icon *ngIf="isActive" [type]="'check'" color="#613CB0"></mathema-slot-icon>
        <div class="no-hover-icon">
          <mathema-slot-icon *ngIf="!isActive && !item.isDisabled && !item.isMarkedForFreeSlot" [type]="'check'"
            color="#1B5E20"></mathema-slot-icon>
        </div>
        <div class="hover-icon">
          <mathema-slot-icon *ngIf="!isActive && !item.isDisabled && !item.isMarkedForFreeSlot"
                             [type]="isSlotDisabled ? 'check' : 'bin'" [color]="isSlotDisabled ? '#1B5E20' : '#E53935'"
                             (click)="$event.stopPropagation(); onClickRemoveFreeSlot()"></mathema-slot-icon>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #weekLessonSlot>
  <div class="item weekly lesson" (click)="$event.stopPropagation(); changeActiveSlotIndex()" [class.checked]="isActive"
    [class.first]="item.type === 'first'" [class.teacher-or-client]="authService.isTeacherOrClient()">
    <ng-template [ngTemplateOutlet]="weekItemContent"></ng-template>
  </div>
</ng-template>

<ng-template #weekFreeTimeSlot>
  <div [dndDisableIf]="teacherSearchMode || isSlotDisabled || isVacation" dndDropzone
       (dndDrop)="onLessonDroppedInSlot($event, item)" class="item weekly slot"
       (click)="$event.stopPropagation(); onClickFreeSlot()" [class.checked]="isActive"
       [class.teacher-or-client]="authService.isTeacherOrClient()">
    <ng-template [ngTemplateOutlet]="weekItemContent"></ng-template>
  </div>
</ng-template>

<ng-template #weekItemContent>
  <div class="chevron" [class.first]="item.type === 'first'" [class.regular]="item.type === 'regular'"
    [class.reserved]="item.status === 'reserved'" [class.booked]="item.status === 'booked'"
    [class.conducted]="item.isConducted" [class.checked]="isActive" [class.slot]="!item.isLesson"
    [class.hidden]="!item.isLesson && !authService.isTeacherOrClient()">
  </div>
  <div class="item-content" nz-dropdown nzTrigger="click" [nzDropdownMenu]="freeSlotSubmenu"
       [nzDisabled]="item.isLesson || authService.isTeacherOrClient() || teacherSearchMode || isSlotDisabled || isVacation" [nzOverlayClassName]="'free-slot-submenu'">
    <div class="info">
      <div class="time">{{item.time}}</div>
      <div class="labels" *ngIf="isTimeHourOrMore(item.time)">
        <div *ngIf="!item.student" class="label slot" [class.checked]="isActive">{{'calendar.slot.available' | translate}}</div>
        <ng-container *ngIf="authService.isTeacherOrClient()">
          <div *ngIf="item.type === 'regular'" class="label regular" [class.checked]="isActive">{{'just_regular' | translate}}</div>
          <div *ngIf="item.type === 'first'" class="label first" [class.checked]="isActive">{{'just_first' | translate}}</div>
          <div *ngIf="item.status === 'booked' && !item.isConducted" class="label paid" [class.checked]="isActive">
            {{'paid' | translate}}</div>
          <div *ngIf="item.status === 'reserved'" class="label reserved" [class.checked]="isActive"
            [class.next-subscription]="item.markedForFutureSubscription && !authService.isTeacherOrClient()">{{'reserved' | translate}}</div>
        </ng-container>
      </div>
      <div *ngIf="item.student; else freeSlotLabel">
        {{item.student.firstName}} {{item.student.lastName}}
      </div>
      <ng-template #freeSlotLabel>
        <div class="free-slot-text">{{'free-slot' | translate}}</div>
      </ng-template>
    </div>
    <div class="icons">
      <ng-container *ngIf="item.type === 'first'">
        <mathema-slot-icon *ngIf="item.status === 'reserved' && !authService.isTeacherOrClient()" [type]="'reserved'"
          [color]="isActive && '#fff'"></mathema-slot-icon>
        <mathema-slot-icon *ngIf="item.isConducted" [type]="'conducted'" [color]="isActive && '#fff'"
          [color2]="isActive && '#1CC28FFF'"></mathema-slot-icon>
      </ng-container>

      <ng-container *ngIf="item.type === 'regular'">
        <mathema-slot-icon *ngIf="item.isConducted" [type]="'conducted'" [color]="isActive && '#fff'"
          [color2]="isActive && '#1CC28FFF'"></mathema-slot-icon>

        <ng-container *ngIf="item.status === 'reserved' && !authService.isTeacherOrClient()">
          <mathema-slot-icon *ngIf="item.dealId" [type]="'reserved'"
            [color]="isActive ? '#fff' : (item.markedForFutureSubscription && !authService.isTeacherOrClient() && '#FF6868FF')"></mathema-slot-icon>
          <mathema-slot-icon *ngIf="!item.dealId" [type]="'reserved'"
            [color]="isActive ? '#004D40' : '#949494'"></mathema-slot-icon>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!item.isLesson && !authService.isTeacherOrClient() && !teacherSearchMode && !isSlotDisabled && !isVacation">
        <mathema-slot-icon [type]="'add-slot'"></mathema-slot-icon>
      </ng-container>
    </div>
  </div>
</ng-template>

<nz-dropdown-menu #freeSlotSubmenu="nzDropdownMenu">
  <ul nz-menu>
    <ng-container *ngIf="scheduleCalendarService.activeTeacher.value as teacher; else firstLessonWithoutTeacher">
      <li nz-menu-item
        (click)="(!!teacher.notTakeNewStudentsAt || teacher.blockedAvailability) ? null : changeActiveSlotIndex('firstLesson')"
        [nzDisabled]="!!teacher.notTakeNewStudentsAt || teacher.blockedAvailability" nz-tooltip
        [nzTooltipTrigger]="(teacher.notTakeNewStudentsAt || teacher.blockedAvailability) ? 'hover' : null"
        [nzTooltipTitle]="getTeacherBlockedTooltipForStuff(teacher.notTakeNewStudentsAt, teacher.blockedAvailability, teacher.blockedAt)">
        <span>{{'first_lesson' | translate}}</span>
      </li>
    </ng-container>
    <ng-template #firstLessonWithoutTeacher>
      <li nz-menu-item (click)="changeActiveSlotIndex('firstLesson')">{{'first_lesson' | translate}}</li>
    </ng-template>
  </ul>
</nz-dropdown-menu>
