import { Component, Inject, Injector, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, interval, Observable, Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { catchError, finalize, map } from 'rxjs/operators';
import { NZ_DRAWER_DATA, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import ScheduleApiService, { MonthlyCalendarDay } from '../../schedule-api.service';
import ScheduleCalendarService, { ScheduleCalendarType } from '../schedule-calendar.service';
import LanguageService from '@shared/language/language.service';
import { TIME_SLOTS_INDICES, TIME_HOURS } from '@shared/constants';

const slotHeight = 60;

@Component({
  selector: 'mathema-schedule-day-calendar',
  templateUrl: './schedule-day-calendar.component.html',
  styleUrls: ['./schedule-day-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleDayCalendarComponent implements OnInit {

  public isDrawer: boolean;
  public drawerRef: NzDrawerRef;

  public isModal: boolean;
  public modalRef: NzModalRef;

  public calendarDate: Date;

  public TIME_HOURS: string[] = TIME_HOURS;
  public RULER_HOURS: string[] = TIME_HOURS;
  public calendarView$: Observable<MonthlyCalendarDay>;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);
  public currentTime$: Observable<string> = interval(1000).pipe(
    map(() => {
      const now = new Date();
      const [hour, minute] = now.toTimeString().split(':');

      return `${hour}:${minute}`;
    }),
  );

  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly breakpointObserver: BreakpointObserver,
    private injector: Injector,
    @Optional() @Inject(NZ_DRAWER_DATA) public drawerData: {
      calendarDate: Date
    },
    @Optional() @Inject(NZ_MODAL_DATA) public modalData: {
      calendarDate: Date
    },
  ) {
    this.isDrawer = !!drawerData;
    this.isModal = !!modalData;
    this.calendarDate = drawerData?.calendarDate || modalData.calendarDate;
  }

  public ngOnInit(): void {
    if (this.isDrawer) {
      this.drawerRef = this.injector.get(NzDrawerRef);
      this.loadInnerDayView();
      this.subscribeToActiveSlot();
    } else if (this.isModal) {
      this.modalRef = this.injector.get(NzModalRef);
      this.loadInnerDayView();
      this.subscribeToActiveSlot();
    } else {
      this.subscribeToBreakpoint();
      this.subscribeToCalendarState();
    }
  }

  public closeDrawerOrModal(): void {
    if (this.isDrawer) {
      this.drawerRef.close();
    } else if (this.isModal) {
      this.modalRef.close();
    }
  }

  private loadInnerDayView(): void {
    const year = this.calendarDate.getFullYear();
    const month = this.calendarDate.getMonth();
    const day = this.calendarDate.getDate();
    this.calendarView$ = this.scheduleApiService.getDailyCalendar({
      year,
      month,
      day,
      ...this.scheduleCalendarService.calendarFilters.value,
    }).pipe(
      finalize(() => {
        requestAnimationFrame(() => {
          let body: Element;

          if (this.isDrawer) {
            const drawerWrapper = document.getElementsByClassName('day-calendar-drawer')[0];
            body = drawerWrapper.getElementsByClassName('ant-drawer-body')[0];
          } else if (this.isModal) {
            const modalWrapper = document.getElementsByClassName('day-calendar-modal')[0];
            body = modalWrapper.getElementsByClassName('day-calendar')[0];
          }

          body.scrollTop = 960; // 08:00 by default
        });
      }),
      catchError(() => {
        this.loadingError$.next(true);
        return EMPTY;
      }),
    );
  }

  public handleSelectedSlot(): void {
    const activeSlot = this.scheduleCalendarService.activeSlot.value;

    if (activeSlot.type === 'freeSlot') {
      if (this.isModal) {
        this.modalRef.close();
      } else if (this.isDrawer) {
        this.drawerRef.close();
      }
    }
  }

  private subscribeToActiveSlot(): void {
    this.subscriptions.push(this.scheduleCalendarService.activeSlot.subscribe(activeSlot => {
      if (!activeSlot) {
        return;
      }

      if (['firstLesson', 'additionalLesson'].includes(activeSlot.freeSlotAction)) {
        return;
      }

      if (this.isModal) {
        this.modalRef.close();
      } else if (this.isDrawer) {
        this.drawerRef.close();
      }
    }));
  }

  private subscribeToBreakpoint(): void {
    this.subscriptions.push(this.breakpointObserver.observe(['(min-width: 576px)']).subscribe(result => {
      if (result.matches) {
        this.scheduleCalendarService.calendarType.next(ScheduleCalendarType.WEEK);
      }
    }));
  }

  private subscribeToCalendarState(): void {
    const state = combineLatest([
      this.scheduleCalendarService.calendarDate,
      this.scheduleCalendarService.calendarFilters,
    ]);

    const calendarViewSub = state.subscribe(([calendarDate, calendarFilters]) => {
      this.loadingError$.next(false);

      const year = calendarDate.getFullYear();
      const month = calendarDate.getMonth();
      const day = calendarDate.getDate();
      this.calendarView$ = this.scheduleApiService.getDailyCalendar({
        year,
        month,
        day,
        ...calendarFilters,
      }).pipe(
        finalize(() => {
          requestAnimationFrame(() => {
            document.getElementById('dayCalendar').scrollTop = 960; // 08:00 by default
          })
        }),
        catchError(() => {
          this.loadingError$.next(true);
          return EMPTY;
        }),
      );
    });

    this.subscriptions.push(calendarViewSub);
  }

  public formatDate(date: string): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    const monthDay = new Date(date);

    return formatter.format(monthDay);
  }

  public getTopOffsetFromTime(time: string): number {
    const hour = Number(time.split(':')[0]);
    return hour * slotHeight;
  }

  public getTopOffsetForSlot(time: string): number {
    const [hour, minute] = time.split(' - ')[0].split(':').map(Number);
    return (hour * slotHeight) + minute;
  }

  public getTopOffsetForCurrentTime(time: string): number {
    const [hour, minute] = time.split(':').map(Number);
    const headerHeight = (this.isDrawer || this.isModal) ? 72 : 36;
    return (hour * slotHeight) + minute + headerHeight;
  }

  public getHeightFromTime(time: string): number {
    const [startTime, endTime] = time.split(' - ');
    return (TIME_SLOTS_INDICES[endTime] - TIME_SLOTS_INDICES[startTime]) * 5 - 1;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
